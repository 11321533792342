import img1 from "../assets/images/carousel1.png";
import img2 from "../assets/images/carousel2.png";
import img3 from "../assets/images/carousel3.png";
import img4 from "../assets/images/carousel4.png";
import img5 from "../assets/images/carousel5.png";
import img6 from "../assets/images/carousel6.png";
import img7 from "../assets/images/carousel7.png";
import img8 from "../assets/images/carousel8.png";
import {
  SliderDisplayDataType,
  AlbumlistDataType,
  SermaonlistDataType,
  ExploreData,
  ExploreData2,
  Ministration,
} from "../types";
import m1 from "../assets/images/m1.svg";
import m2 from "../assets/images/m2.svg";
import m3 from "../assets/images/m3.svg";
import m4 from "../assets/images/m4.svg";
import m5 from "../assets/images/m5.svg";
import m6 from "../assets/images/m6.svg";
import m7 from "../assets/images/m7.svg";
import s1 from "../assets/images/sermon1.png";
import s2 from "../assets/images/sermon2.png";
import s3 from "../assets/images/sermon3.png";
import s4 from "../assets/images/sermon4.png";
import s5 from "../assets/images/sermon5.png";
import s6 from "../assets/images/sermon6.png";
import mg from "../assets/images/Chants.png";
import mg2 from "../assets/images/Thanksgiven.png";
import mg3 from "../assets/images/Chant2.png";
import mg4 from "../assets/images/Praise.png";
import mg5 from "../assets/images/Chant3.png";
import mg6 from "../assets/images/Praise2.png";
import mg7 from "../assets/images/Soul.png";
import mg8 from "../assets/images/Praise3.png";
import mg9 from "../assets/images/Soul2.png";
import mg10 from "../assets/images/Thanks.png";
import mg11 from "../assets/images/Praise4.png";
import mg12 from "../assets/images/Chant4.png";
import min1 from "../assets/images/minister.png";
import min2 from "../assets/images/minister2.png";
import min3 from "../assets/images/minister3.png";
import min4 from "../assets/images/minister4.png";
import min5 from "../assets/images/minister5.png";


export const sliderDisplayData: SliderDisplayDataType[] = [
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img1,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img2,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img3,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img4,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img5,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img6,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img7,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img8,
  },
];

export const albumListData: AlbumlistDataType[] = [
  {
    title: "Yeshua Hamashiach(Overture)",
    artist: "Nathaniel Bassey",
    duration: "7:56",
  },
  {
    title: "Ruach Elohim",
    artist: "Nathaniel Bassey",
    duration: "10:01",
  },
  {
    title: "Lift up your head (Psalm 24)",
    artist: "Nathaniel Bassey",
    duration: "5:39",
  },
  {
    title: "See What the Lord Has done",
    artist: "Nathaniel Bassey",
    duration: "7:34",
  },
  {
    title: "Yeshua Hamashiach(Overture)",
    artist: "Nathaniel Bassey",
    duration: "7:56",
  },
  {
    title: "Ruach Elohim",
    artist: "Nathaniel Bassey",
    duration: "10:01",
  },
  {
    title: "Lift up your head (Psalm 24)",
    artist: "Nathaniel Bassey",
    duration: "5:39",
  },
  {
    title: "See What the Lord Has done",
    artist: "Nathaniel Bassey",
    duration: "7:34",
  },
];

export const albumMenuModalData = [
  {
    title: "Add to library",
    icon: m1,
  },
  {
    title: "Save to playlist",
    icon: m2,
  },
  {
    title: "Go to artist",
    icon: m3,
  },
  {
    title: "Shuffle play",
    icon: m4,
  },
  {
    title: "Play next",
    icon: m5,
  },
  {
    title: "Report",
    icon: m6,
  },
  {
    title: "Share",
    icon: m7,
  },
];

export const Sermon: SermaonlistDataType[] = [
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s1,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s2,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s3,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s4,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img2,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s5,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: s6,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img1,
  },
];

export const Mood: ExploreData[] = [
  {
    image: mg,
  },
  {
    image: mg2,
  },
  {
    image: mg3,
  },
  {
    image: mg4,
  },
  {
    image: mg12,
  },
  {
    image: mg5,
  },
  {
    image: mg6,
  },
];

export const Mood2: ExploreData2[] = [
  {
    image: mg7,
  },
  {
    image: mg8,
  },
  {
    image: mg9,
  },
  {
    image: mg2,
  },
  {
    image: mg10,
  },
  {
    image: mg11,
  },
  {
    image: mg12,
  },
];

export const minister: Ministration[] = [
  {
    image: min1,
    title: "Apt. Joshua Selman"
  },
  {
    image: min2,
    title: "Apt. Arome Osayi"
  },
  {
    image: min3,
     title: "Pst. Jerry Eze"
  },
  {
    image: min4,
     title: "Apt. Emmanuel Iren"
  },
  {
    image: min5,
     title: "rev. Sam Ade"
  },

];
